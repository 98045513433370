/* A subtle background for the entire page (optional) */
body {
  background: #f9f9f9;
  margin: 0;
  font-family: "Helvetica Neue", Arial, sans-serif;
}


.critique-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  background: linear-gradient(135deg, #f3f4f6, #ffffff);
  padding: 20px;
}


/* Page title */
.critique-container h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 24px;
}

/* 
  Action Buttons Section 
  (If you have multiple buttons like "Fix Resume," "Generate Cover Letter," etc.)
*/
.action-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 24px;
}

.action-btn, .fetch-critique-btn {
  background: #007bff;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.action-btn:hover,
.fetch-critique-btn:hover {
  background: #0056b3;
}


/* Progress ring container and transitions */
.progress-ring {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.progress-ring__progress {
  transition: stroke-dashoffset 1s ease-in-out;
}

.score-text {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

/* Fade-out utility (e.g., after animation completes) */
.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none; /* Prevent interaction while fading out */
}


.critique-page {
  display: flex;
  flex-wrap: wrap; /* So on small screens, they stack */
  max-width: 1200px; /* Or whatever fits your design */
  margin: 0 auto;
  padding: 20px;
  gap: 20px; /* Space between left and right modules */
}

/* LEFT MODULE */
.left-module {
  flex: 0 0 300px; /* A fixed width on desktop */
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* RIGHT MODULE */
.right-module {
  flex: 1; /* Take remaining space */
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.analyzing-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add to ResumeCritiquePage.css */
.highlight {
  color: #007bff;
  font-weight: bold;
  position: relative;
}

/* Blue dot for highlighted text */
.highlight::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -8px;
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
}


/* Professional button styling */
.optimize-button {
  background: #2563eb;
  color: white;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 25px auto;
  width: auto;
  min-width: 300px;
}

.optimize-button:hover {
  background: #1e40af;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.optimize-button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

/* Button icon */
.optimize-button-icon {
  font-size: 18px;
}


/* Add these styles to your ResumeCritiquePage.css file */

/* Base optimize button styles - keep any existing styles you already have */
.optimize-button {
  position: relative;
  background-color: #2563eb; /* Brighter blue */
  color: white;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Glow effect on hover */
.optimize-button:hover {
  background-color: #1d4ed8; /* Slightly darker on hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(37, 99, 235, 0.5);
}

/* Pulsing glow animation */
.optimize-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 65%);
  opacity: 0;
  z-index: -1;
  animation: pulse-glow 2.5s infinite;
}

@keyframes pulse-glow {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

/* Active state */
.optimize-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 8px rgba(37, 99, 235, 0.4);
}

/* Icon styling */
.optimize-button-icon {
  margin-right: 8px;
}

/* Add flash effect for extra attention */
@keyframes flash-border {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(37, 99, 235, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(37, 99, 235, 0);
  }
}

/* Apply flash animation */
.optimize-button.attention {
  animation: flash-border 1.5s infinite;
}

/* Make button more prominent */
.optimize-button {
  min-width: 240px;
  font-size: 16px;
  letter-spacing: 0.3px;
}